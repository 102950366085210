// 订单列表
import request from '@/utils/request'

// 列表查询初始化
export function pageInit() {
   return request({
     url: "/api/amzorder/pageInit",
     method: "get"
   })
}

// 列表查询
export function getListPage(params) {
   return request({
     url: "/api/amzorder/listPage",
     params,
     method: "get"
   })
}

// 查看详情
export function getShowDetail(id) {
   return request({
      url: "/api/amzorder/" + id,
      method: "get"
   })
}

// 编辑初始化
export function updateInit(id) {
   return request({
    url: "/api/amzorder/updateInit/" + id,
    method: "get" 
   })
}

// 订单编辑
export function confirmEdit(data) {
   return request({
      url: "/api/amzorder",
      data,
      method: "put"  
   })
}